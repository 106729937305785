import { joinURL } from 'ufo'
import type { ScrollbarInst } from 'naive-ui'
import { refDebounced } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import type { OfferStatus, OfferType } from '~/types'
import { offerStatusToGerman, offerTypesToGerman } from '~/translations'

type OnItemSelect = () => unknown | Promise<unknown>
export interface CommandPaletteItem {
  key: string
  label: string
  displayLabel: string
  iconName?: string
  tags?: string[]
  onSelect: OnItemSelect
  noClose?: boolean
}

const PATH_DISPLAY_LABEL_SPLIT = ' - '

export default (onSelect?: OnItemSelect) => {
  const searchText = ref('')
  const debouncedSearchText = refDebounced(searchText, 350)

  // Page Searching
  const { menuItemsConfig } = useMenuConfig()
  const { canAccessPage } = useAuthorization()
  function makeCommandPaletteItemFromMenuConfig(path: string, config: MenuItemConfig, prefix?: string): CommandPaletteItem[] {
    if (!canAccessPage(path)) {
      return []
    }

    let displayLabel: string | undefined
    if (typeof config.label === 'string') {
      displayLabel = [...(prefix ? [prefix] : []), config.label].join(PATH_DISPLAY_LABEL_SPLIT)
    } else {
      displayLabel = prefix
    }

    const children: CommandPaletteItem[] = []
    const childrenToRender = config.children ? Object.entries(config.children).filter(([_, childConfig]) => childConfig.doRender) : []
    if (childrenToRender.length > 0) {
      childrenToRender.forEach(
        ([childPath, childConfig]) => children.push(...makeCommandPaletteItemFromMenuConfig(joinURL(path, childPath), childConfig, displayLabel)),
      )
    }

    if (typeof config.label !== 'string' || !displayLabel || !config.isRouterLink) {
      return children
    }

    const tags = ['H-OS']
    if (prefix) {
      tags.push(...prefix.split(PATH_DISPLAY_LABEL_SPLIT))
    }

    const baseItem: CommandPaletteItem = {
      key: path,
      label: config.label,
      displayLabel,
      iconName: config.icon,
      onSelect: () => navigateTo(path),
      tags,
    }
    return [baseItem, ...children]
  }

  const pageOptions: CommandPaletteItem[] = Object.entries(menuItemsConfig.value)
    .filter(([_, config]) => config.doRender)
    .map(([path, config]) => makeCommandPaletteItemFromMenuConfig(path, config))
    .flat()

  // Offer Searching
  const { commandPalette } = useQuery()
  const { data: offers, isLoading: areOffersLoading } = commandPalette.offers(debouncedSearchText)

  const offerTypeToIcon: Record<OfferType, string> = {
    'rental': 'material-symbols:acute-outline-rounded',
    'sale': 'material-symbols:shopping-cart-checkout-rounded',
    // TODO: Currently we only support offers of type rental & sale, later we want to support all
    // See: https://github.com/sidestream-tech/hanselmann-os/pull/3296#issuecomment-2330724666
    'special': 'material-symbols:award-star-outline-rounded',
    'service-project': 'material-symbols:design-services-outline-rounded',
  }

  const { openOfferPage } = useGlobalOpeners()
  const offerOptions = computed((): CommandPaletteItem[] => {
    if (!offers.value) {
      return []
    }
    return offers.value.map(({ id, type, status, cuid }) => ({
      key: cuid,
      label: id,
      displayLabel: `${id}`,
      iconName: offerTypeToIcon[type as OfferType],
      onSelect: () => openOfferPage({ mode: 'edit', id }),
      tags: [offerTypesToGerman[type as OfferType], offerStatusToGerman[status as OfferStatus]],
    }))
  })

  // Compose Searching Parameters
  const computedSelectOptions = computed(() => ([
    ...pageOptions,
    ...offerOptions.value,
  ]))

  const isSearching = computed(() => searchText.value !== debouncedSearchText.value || areOffersLoading.value)
  const { results } = useFuse(debouncedSearchText, computedSelectOptions, {
    fuseOptions: {
      includeScore: true,
      keys: ['label', 'displayLabel', 'href'],
    },
    resultLimit: 10,
  })

  const suggestions = computed(() => {
    if (results.value.length === 0 && debouncedSearchText.value.length === 0) {
      return pageOptions
    }
    return results.value.filter(({ score }) => !score || score < 0.2).map(({ item }) => item)
  })

  // Scrolling
  interface ScrollbarRef extends ScrollbarInst {
    scrollbarInstRef: {
      containerScrollTop: number
    }
  }
  const scrollRef = ref<ScrollbarRef | null>(null)

  const selectedIndex = ref(0)
  watch(debouncedSearchText, () => scrollToIndex(0))

  // Height + Margin in Pixel of each Item
  const ITEM_SCROLL_HEIGHT = 64
  function scrollToIndex(index: number) {
    if (index < 0 || index >= suggestions.value.length) {
      return
    }

    selectedIndex.value = index
    if (scrollRef.value) {
      const itemScrollTop = index * ITEM_SCROLL_HEIGHT
      scrollRef.value.scrollTo(0, itemScrollTop)
    }
  }

  // Key watchers
  async function selectItemByIndex(index: number) {
    const option = suggestions.value[index]
    await option.onSelect()
    if (onSelect && !option.noClose) {
      await onSelect()
    }
  }

  async function onKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault()
        scrollToIndex(selectedIndex.value + 1)
        break
      }
      case 'ArrowUp': {
        e.preventDefault()
        scrollToIndex(selectedIndex.value - 1)
        break
      }
      case 'Enter': {
        e.preventDefault()
        await selectItemByIndex(selectedIndex.value)
      }
    }
  }

  return { suggestions, searchText, selectedIndex, isSearching, onKeyDown, scrollRef, selectItemByIndex }
}
